import React, { useState, useEffect } from "react";
import '../../styles.css';
import splashlogo from '../../assets/splashlogo_extrasmall.gif';
import './Splash.scss';

function Splash() {
    return (
        <div className="SplashContainer">
            <img className="splashlogoimage" src={splashlogo}/>
        </div>
    )
}

export default Splash;