import React, { useEffect, useState } from "react";
import '../../styles.css';
import './ContactUsBanner.scss'

function ContactUsBanner() {
    const [imageUrl, setImageUrl] = useState(null);
    const imageDescription = 'A dynamically fetched image';

    useEffect(() => {
        fetch('https://picsum.photos/id/0/info')
            .then(response => response.json())
            .then(data => setImageUrl(data.url));
    }, []);

    return (
        <div className="banner">
            <div className="bg-box">
            
                {/* <img className="bg-lg" url={imageUrl} description={imageDescription} />
                <img className="bg-xs" url={imageUrl} description={imageDescription} /> */}
                <img className="bg-lg" url="https://img-4-cdn.airdroid.com/assets/img/contactUs/pic_head-494a9f12d6.png" description={imageDescription} />
                <img className="bg-xs" url="https://img-4-cdn.airdroid.com/assets/img/contactUs/pic_head-494a9f12d6.png" description={imageDescription} />
            </div>
            {/* <div className="bread-link">
                <a href="/business/" className="bread-link-txt">Business Home </a>
                <span className="bread-link-txt">Digital Signage &amp;Multimedia</span>
            </div> */}
            <div className="banner-content-box">
                <div className="banner-img sale"></div>
                <h1 className="banner-title">Contact Business Sales Team</h1>
                <p className="banner-txt">Please submit your information and a representative will get in touch with you.<br/>For technical inquiries regarding Mirror2050 Business and/or Mirror2050 Remote Support for Business, please contact our technical support team.</p>
                {/* <div className="btn-box">
                    <a href="/mobile-device-management-free-trial/?from=signage&amp;trial_source=digital_signage" className="btn j-biz-apply" data-type="bizApply" data-gatype="trial1" rel="nofollow">Schedule Demo</a>
                    <a href="/contact-us/sales/" className="btn btn-primary biz" rel="nofollow">Contact sales</a>
                </div>
                <p className="banner-tip">
                    Try all features for free without any additional setup fees.<a className="book-demo-link j-to-biz-demo-click" href="javascript:;">Schedule a Demo &gt;</a>
                </p> */}
            </div>
        </div>
    );
}

export default ContactUsBanner;