import React from "react";
import '../../styles.css';
import './IntroPanel.scss'

function IntroPanel() {
    return (
        <div className="intro-panel intro-panel-3">
            <div className="panel-box">
                <h2 className="panel-title">Productively dealing with all your computerized signages in a single spot</h2>
                <p className="panel-txt">Mirror 2050 allows you from a distance to screen your Android computerized screens from one view. Begin working on inside correspondence and client commitment.</p>
                <div className="panel-content-list i-clearfix">
                    <h3 className="panel-list-title">
                        <span>Our Benefits</span>
                    </h3>
                    <div className="panel-content">
                        <div className="panel-content-item">
                            <div className="panel-content-box">
                                <div className="item-num">1</div>
                                <p className="panel-content-txt">Oversee screens all the more successfully with a concentrated dashboard</p>
                            </div>
                        </div>
                        <div className="panel-content-item">
                            <div className="panel-content-box">
                                <div className="item-num">2</div>
                                <p className="panel-content-txt">Find every signage gadget when there are hundreds to make due</p>
                            </div>
                        </div>
                        <div className="panel-content-item">
                            <div className="panel-content-box">
                                <div className="item-num">3</div>
                                <p className="panel-content-txt">Arrange gadget availability to further develop security strategies</p>
                            </div>
                        </div>
                        <div className="panel-content-item">
                            <div className="panel-content-box">
                                <div className="item-num">4</div>
                                <p className="panel-content-txt">Decrease costs through controller</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="btn-box">
                    <a href="/mobile-device-management-free-trial/?from=signage&amp;trial_source=digital_signage" className="panel-btn utm-biz-sol-apply" rel="nofollow">Try Now</a>
                </div>
            </div>
        </div>
    );
}

export default IntroPanel;