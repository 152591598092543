import Footer from "../components/Footer/Footer";
import Navbar from "../components/Navbar/Navbar";

const PrivacyPolicy = ()=>{
    return (
        <>
        <Navbar/>
        <br/><br/><br/><br/><br/>
<p><strong>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Privacy Policy</strong></p>
<p><strong>What our identity is</strong></p>
<p>(&quot;we&quot;, &quot;us&quot; or &quot;our&quot;) regards your security and values the trust you place in us when you share your own information with us. This protection strategy sets out how we gather, interaction and utilize your own information when you access and utilize our administrations, including the whole Mirror2050 product offering (aggregately &quot;Mirror2050&quot;), our sites (www.mirror2050.com and all sub-areas under mirror2050.com) and all administrations given by us every once in a while (by and large the &quot;Administrations&quot;).</p>
<p>&nbsp;</p>
<p><strong>What information we gather and how we use them</strong></p>
<p>We set out beneath the sorts of information we gather and the reasons for which we use them.</p>
<p>&nbsp;</p>
<p>What we collect &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;How we use it</p>
<p>1</p>
<p>Data gave during your record set-up process, which incorporates:</p>
<p>&nbsp;</p>
<p>&bull; Your username</p>
<p>&bull; Your secret key</p>
<p>&bull; Your email address</p>
<p>&bull; IP address</p>
<p>For Mirror2050 Business as it were:</p>
<p>&nbsp;</p>
<p>&bull; Your complete name</p>
<p>&bull; Your organization name</p>
<p>&bull; Your organization address and telephone number</p>
<p>&bull; Set up and enlist represent you and empower you to sign in the record</p>
<p>&nbsp;</p>
<p>&bull; Process your buy request for our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Confirm clients&apos; personality and forestall extortion and security chances</p>
<p>&nbsp;</p>
<p>&bull; Know more the way in which you utilize your record and customize your experience</p>
<p>&nbsp;</p>
<p>2</p>
<p>At the point when you access and utilize our Administrations, we will gather:</p>
<p>&nbsp;</p>
<p>&bull; IP address</p>
<p>&bull; Your record settings</p>
<p>&bull; Your record inclinations</p>
<p>&bull; Gadget data, for example, your IMEI, IMSI, Macintosh, Android ID, working framework variant, framework language, computer processor model, memory, hard circle and its use, and so on.</p>
<p>&bull; Arrangement of Administrations</p>
<p>&nbsp;</p>
<p>&bull; Distinguish issues with our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Work on our Administrations</p>
<p>&nbsp;</p>
<p>3</p>
<p>&quot;Tap&quot; conduct data when you use and work Mirror2050 (for example the way of behaving of tapping &quot;Record Moving&quot; button, &quot;Applications&quot;, &quot;Reinforcement&quot; button, and so forth.)</p>
<p>&nbsp;</p>
<p>&bull; Foster new Administrations</p>
<p>&nbsp;</p>
<p>&bull; Work on our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Measurable examination</p>
<p>&nbsp;</p>
<p>4</p>
<p>Data that we gather through your utilization of our sites including:</p>
<p>&nbsp;</p>
<p>&bull; Your record data assuming you register or sign in your record on our sites</p>
<p>&bull; Date and season of solicitations</p>
<p>&bull; The solicitations you make</p>
<p>&bull; The pages you visit</p>
<p>&bull; IP address</p>
<p>&bull; Offer our Types of assistance</p>
<p>&nbsp;</p>
<p>&bull; Safeguard the security of your data</p>
<p>&nbsp;</p>
<p>&bull; Recognize issues with our sites</p>
<p>&nbsp;</p>
<p>&bull; Screen the way the way in which our sites is utilized</p>
<p>&nbsp;</p>
<p>&bull; Work on our sites</p>
<p>&nbsp;</p>
<p>5</p>
<p>Data on exchange, for example at the point when you buy our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Complete the exchange</p>
<p>&nbsp;</p>
<p>&bull; Store as exchange history for future reference</p>
<p>&nbsp;</p>
<p>6</p>
<p>Data when you speak with us whether face to face, through our sites, by means of email, through other input channels given by us, including:</p>
<p>&nbsp;</p>
<p>&bull; Your contact subtleties (this might incorporate your web-based entertainment account assuming that is the manner in which you speak with us)</p>
<p>&bull; The subtleties of your interchanges with us</p>
<p>&bull; Log documents you submitted from inside Mirror2050 for item support</p>
<p>&bull; Answer any issues or concerns</p>
<p>&nbsp;</p>
<p>&bull; Screen client correspondences for quality improvement and preparing purposes</p>
<p>&nbsp;</p>
<p>&bull; Foster new administrations</p>
<p>&nbsp;</p>
<p>&bull; Work on our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Customize our Administrations</p>
<p>&nbsp;</p>
<p>&bull; Investigate</p>
<p>&nbsp;</p>
<p>7</p>
<p>At the point when you utilize the &quot;Track down Telephone&quot; highlight on web.mirror2050.com, we will gather:</p>
<p>Gadget area</p>
<p>&nbsp;</p>
<p>&bull; Shown the area of your gadget progressively when it&apos;s lost.</p>
<p>&nbsp;</p>
<p><strong>3 How long we keep your own information</strong></p>
<p>We will save your own information however long it is sensibly fundamental. It will rely upon variables, for example, whether you have a record with us or have communicated with us.</p>
<p>&nbsp;</p>
<p>Your own information will be erased or anonymized when it is not generally required for its initially expressed handling purposes or for any extra reason for which we may legally additionally deal with such information</p>
<p>&nbsp;</p>
<p><strong>4 Sharing of your own information</strong></p>
<p>We don&apos;t sell, rent, lease or offer your own information besides considering the present situation set out underneath:</p>
<p>&nbsp;</p>
<p>&bull; We might impart your own information to different individuals from our gathering of organizations so we can offer the best types of assistance across our gathering. They will undoubtedly keep your data as per this arrangement.</p>
<p>&bull; We may likewise impart your own information to specific workers for hire or specialist co-ops. They will handle your own information for the benefit of us, for instance, data set specialist co-ops, publicizing organizations, or client connection the executives specialist co-ops. Our project workers and specialist organizations will be expected to fulfill our guidelines on handling information and security. The data we give to them, including your own information, might be furnished regarding the presentation of their capabilities.</p>
<p>Your own information might be revealed or moved to other outsider associations under the accompanying conditions:</p>
<p>&nbsp;</p>
<p>&bull; In the event that we are talking about selling or moving part or the entirety of our business - your own information might be moved to planned buyers under appropriate terms as to privacy.</p>
<p>&bull; On the off chance that we are redesigned or sold, your own information might be moved to a purchaser who can keep on offering the Types of assistance to you.</p>
<p>&bull; On the off chance that we are expected to by regulation, or under any administrative code or practice we follow, or on the other hand assuming we are asked by any open or administrative power to uncover your own information.</p>
<p>&bull; In the event that we are guarding a legitimate case, your own information might be uncovered or moved as expected regarding safeguarding such case.</p>
<p>&bull; On the off chance that we have acquired your express assent ahead of time.</p>
<p>Your own information might be shared in the event that it is made unknown and accumulated, as in such conditions the anonymized information will fail to be private information.</p>
<p>&nbsp;</p>
<p><strong>5 Outsider Items and Administrations</strong></p>
<p>We might incorporate or offer specific outsider items or administrations on our sites. These outsider items or administrations have their own protection strategies and they are isolated and autonomous from this strategy. We, subsequently, have no liability or responsibility for the substance and exercises connected with these outsider items or administrations.</p>
<p>&nbsp;</p>
<p><strong>6 Where your own information will be put away</strong></p>
<p>Your own information might be put away and handled in a nation or ward beyond your nation of home, and that nation or locale might have information security rules which are not the same as those of your nation of home.</p>
<p>&nbsp;</p>
<p>Assuming we are to move your own information out of your nation of home, we will try to guarantee that a comparable level of security is managed by setting up sufficient specialized, hierarchical and legitimate measures.</p>
<p>&nbsp;</p>
<p>We store your own information on cloud servers situated across the world. We will just exchange your own information to locales outside the extent of the European General Information Security Guideline (the &quot;GDPR&quot;) where the proper protections set out in the GDPR are set up</p>
<p>&nbsp;</p>
<p><strong>7 Your rights</strong></p>
<p>You might have specific privileges comparable to your own information. We have recorded underneath the privileges you have over your own information.</p>
<p>&nbsp;</p>
<p>&bull; You can pull out your assent whenever on the off chance that you have given assent on a specific handling movement connecting with your own information.</p>
<p>&bull; You can request that we affirm assuming we are handling your own information.</p>
<p>&bull; You can request admittance to your own information.</p>
<p>&bull; You can request to address your own information assuming that it is inaccurate.</p>
<p>&bull; You can request that we erase your own information. Erasure of your own information (account) can likewise be directed without anyone else at info@mirror2050.com.</p>
<p>&bull; You can request that we limit how we utilize your own information.</p>
<p>&bull; You can request that we quit utilizing your own information.</p>
<p>&bull; You reserve the privilege to gripe to the pertinent administrative power.</p>
<p>A thoughtful update that the above freedoms are not outright and will just apply in specific conditions.</p>
<p>&nbsp;</p>
<p>You can practice the above freedoms by reaching us at info@mirror2050.com.</p>
<p>&nbsp;</p>
<p><strong>8 Treats</strong></p>
<p>Treats are little text documents that are put on your gadget and are broadly used to make our sites work and carry out different roles. We utilize the accompanying kinds of treats:</p>
<p>&nbsp;</p>
<p>&bull; Fundamental treats are important to give you administrations and highlights accessible through our sites. Without these treats part or the elements of our sites can&apos;t be all performed appropriately.</p>
<p>&bull; Examination treats are utilized to comprehend how clients draw in with our sites, for instance, to produce site utilization measurements.</p>
<p>&bull; Promoting treats are utilized to show more applicable notices and measure publicizing effort execution.</p>
<p>For data on how we utilize these treats and how you can deal with your treat inclinations, if it&apos;s not too much trouble, contact info@mirror2050.com.</p>
<p>&nbsp;</p>
<p><strong>9 Security</strong></p>
<p>We are focused on guarding your own information. We take sensible and proper specialized and authoritative measures as per material regulations to guarantee that your own information is sufficiently safeguarded against unplanned or unlawful annihilation, harm, misfortune or change, unapproved or unlawful access, divulgence or abuse, and any remaining unlawful types of handling of your own information in our control.</p>
<p>&nbsp;</p>
<p><strong>10 Changes to the architecture</strong></p>
<p>This approach will be refreshed every once in a while.</p>
<p>&nbsp;</p>
<p>We will educate you regarding the refreshed strategy by giving (I) a noticeable connection on [(https://www.mirror2050.com/en/lawful/privacy.html)], and (ii) an unmistakable connection on our sites and Administrations.</p>
<p>&nbsp;</p>
<p><strong>11 How to reach us</strong></p>
<p>Assuming you have any inquiries regarding this strategy, kindly get in touch with us at: <a href="mailto:info@mirror2050.com">info@mirror2050.com</a></p>
<p>&nbsp;</p>
<Footer/>
        </>
    );
}

export default PrivacyPolicy