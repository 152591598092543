import React, { useState } from "react";
import '../../styles.css';
import './Navbar.scss'
import { Link, NavLink } from "react-router-dom";

function Navbar() {
    const [colorChange, setColorchange] = useState(false);
    const [currentUser, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    const changeNavbarColor = () => {
        if (window.scrollY >= 0) {
            setColorchange(true);
        } else {
            setColorchange(false);
        }
    };

    function solution_option(e) {
        e.preventDefault();
        let ele = document.getElementById('solution_option');
        ele.classList.contains('hide') ? ele.classList.remove('hide') : ele.classList.add('hide')
    }

    function logout() {
        sessionStorage.clear();
        window.location.href = '/';
    }

    const [mobileMenu, setMobileMenu] = useState(false);
    const openMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    }

    window.addEventListener("scroll", changeNavbarColor);
    return (
        <>
            <div className={"public-header lang_en j-public-header-init transparent-header biz colorChange"} id="public-header" data-lang="en">
                <div className="logo-box">
                    <a href="/" className="item-logo biz-logo"></a>
                </div>
                <ul className="item-navs">
                    <li className="item-nav-item more-nav" data-type="headerNav-product" id="product-nav">
                        <Link className="nav-title" to="/about-us">
                            About Us <i className="nav-res-new-point" style={{ display: "block" }}></i>
                        </Link>
                    </li>
                    <li className="item-nav-item more-nav" data-type="headerNav-solution">
                        <a className="nav-title" href="javascript:;">
                            Solutions <i className="nav-sol-new-point" style={{ display: "block" }}></i>
                            <span className="more-arrow"></span>
                            <i className="nav-arrow"></i>
                        </a>
                        <div className="more-wrap solution-nav-wrap flex">
                            <div>
                                <ul className="more-header more-panel-list flex">
                                    <li className="more-panel-item hover-effect col-2 longer-hover-effect j-solution-product">
                                        <a className="j-to-header-ga-click" role="button" target="_self" data-gatype="Solution_Business">
                                            {/* <i className="more-header-icon i_logo_link_business_2x"></i>  */}
                                            <div className="more-header-title-box">
                                                <div className="more-header-title">Mobile device management &amp;remote access</div>
                                            </div>
                                            <i className="more-header-title-tipImg i_ic_nextpage"></i>
                                            <p className="more-header-txt">Use Mirror 2050 Business to streamline IT resources, reduce cost, and increase efficiency</p>
                                        </a>
                                    </li>
                                    {/* <li className="more-panel-item hover-effect col-1" data-type="headerNav-/remote-support-for-teams/">
                                        <a className="j-to-header-ga-click" href="/remote-support-for-teams/" target="_self" data-gatype="Solution_RS_Business">
                                            <i className="more-header-icon i_logo_link_rs_2x"></i>
                                            <div className="more-header-title-box">
                                                <div className="more-header-title">Mirror 2050 Remote Support</div>
                                            </div>
                                            <i className="more-header-title-tipImg i_ic_nextpage"></i>
                                            <p className="more-header-txt">Use Mirror 2050 Remote Support to provide 1-to-1 remote assistance &amp;troubleshooting for clients, staffs, and business partners</p>
                                        </a>
                                    </li>
                                    <li className="more-panel-item hover-effect col-1 longer-hover-effect j-solution-product" data-type="headerNav-ai-content:/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                        <a className="j-to-header-ga-click" href="https://www.chatinsight.ai/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" data-gatype="">
                                            <i className="more-header-icon i_logo_link_ai_2x"></i>
                                            <div className="more-header-title-box">
                                                <div className="more-header-title">ChatInsight.AI</div>
                                                <span className="more-header-tip"></span>
                                            </div>
                                            <i className="more-header-title-tipImg i_ic_nextpage"></i>
                                            <p className="more-header-txt">Make AI robots your intelligent business assistant</p>
                                        </a>
                            </li> */}
                                </ul>
                                <ul className="more-body more-panel-list flex">
                                    <li className="more-panel-item col-2">
                                        <ul className="more-body-list">
                                            <li className="d-flex">
                                                <ul className="more-list-item">
                                                    <li className="more-body-item label-item">By Industry</li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/digital-signage/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">IT</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/it-service-provider/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Healthcare</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/unattended-devices/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Automation</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/logistics-and-transportation/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Manufacturing</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/communication/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Marketing</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/education/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Education</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/healthcare/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Logistics</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/healthcare/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Advertisement</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/healthcare/">
                                                        <Link to="/contact-us" className="j-to-header-ga-click more-body-title">Communications</Link>
                                                    </li>
                                                </ul>
                                                <ul className="more-list-item">
                                                    <li className="more-body-item label-item">By Device Number</li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/smb/">
                                                        <Link to="/pricing" className="j-to-header-ga-click more-body-title">Personal</Link>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/enterprise/">
                                                        <Link to="/pricing" className="j-to-header-ga-click more-body-title">Business</Link>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <li className="more-panel-item col-1">
                                        <ul className="more-body-list">
                                            <li>
                                                <ul className="more-list-item">
                                                    <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-2">
                                                        <a href="/remote-support-for-teams/#rs-solution-item-2" className="j-to-header-ga-click more-body-title" target="_self" data-gatype="Solution_RS_Business_Hardware">Hardware manufacturers</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-3">
                                                        <a href="/remote-support-for-teams/#rs-solution-item-3" className="j-to-header-ga-click more-body-title" target="_self" data-gatype="Solution_RS_Business_IT">IT system integrators and software providers</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-1">
                                                        <a href="/remote-support-for-teams/#rs-solution-item-1" className="j-to-header-ga-click more-body-title" target="_self" data-gatype="Solution_RS_Business_Inhouse">In-house IT support team</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li> */}
                                    {/* <li className="more-panel-item col-1">
                                        <ul className="more-body-list">
                                            <li>
                                                <ul className="more-list-item">
                                                    <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/employee-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                        <a href="https://www.chatinsight.ai/solutions/employee-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" className="j-to-header-ga-click more-body-title" target="_blank" data-gatype="">Internal Employee Support</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/customer-service-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                        <a href="https://www.chatinsight.ai/solutions/customer-service-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" className="j-to-header-ga-click more-body-title" target="_blank" data-gatype="">Success Customer Support</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/it-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                        <a href="https://www.chatinsight.ai/solutions/it-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" className="j-to-header-ga-click more-body-title" target="_blank" data-gatype="">IT Technical Support</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/marketing-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                        <a href="https://www.chatinsight.ai/solutions/marketing-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" className="j-to-header-ga-click more-body-title" target="_blank" data-gatype="">Marketing Conversational Assistant</a>
                                                    </li>
                                                    <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/?anchor=empower&amp;tab_key=5&amp;utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                        <a href="https://www.chatinsight.ai/?anchor=empower&amp;tab_key=5&amp;utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" className="j-to-header-ga-click more-body-title" target="_blank" data-gatype="">Sales Support Assistant</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </li>
                    {/* <li className="item-nav-item more-nav" style={{ position: "relative" }} data-type="headerNav-resources">
                        <a className="nav-title" href="javascript:;">
                            Blog <i className="nav-res-new-point" style={{ display: "block" }}></i>
                        </a>
                    </li> */}
                    <li className="item-nav-item more-nav" style={{ position: "relative" }} data-type="headerNav-resources">
                        <Link className="nav-title" to="/contact-us">
                            Contact Us <i className="nav-res-new-point" style={{ display: "block" }}></i>
                        </Link>
                    </li>
                    <li className="item-nav-item more-nav" style={{ position: "relative" }} data-type="headerNav-resources">
                        <Link className="nav-title" to="/pricing">
                            Pricing <i className="nav-res-new-point" style={{ display: "block" }}></i>
                        </Link>
                    </li>
                    <li className="item-nav-item more-nav" style={{ position: "relative" }} data-type="headerNav-resources">
                        <a className="nav-title blink_me" download="Mirror_2050.apk" href="/Mirror2050.apk">
                            Download Now <i className="nav-res-new-point" style={{ display: "block" }}></i>
                        </a>
                    </li>
                </ul>
                <div className="right-box">
                    <div className="btn-box left-btn-box">
                        <a id="go_web" className="header-btn header-btn-common i-hide j-to-header-ga-click" data-type="headerNav-goWeb" data-gatype="Mirror 2050_Web" href="javascript:;">Mirror 2050 Web</a>
                        <a id="go_cast_web" className="header-btn header-btn-common i-hide j-to-header-ga-click j-to-cast-web" data-type="headerNav-goWeb" data-gatype="Cast_Web" href="javascript:;">Mirror 2050 Cast Web</a>
                        <a id="go_parental_web" className="header-btn header-btn-common i-hide j-to-header-ga-click j-to-parental-web" data-type="headerNav-goWeb" data-gatype="Parental_Web" href="javascript:;">Mirror 2050 Parental Control Web</a>
                        <a id="go_biz_demo" className="header-btn header-btn-common j-to-biz-demo-click blink_me" download="Mirror_2050.pdf" href="/know_more.pdf" >Know More</a>
                        <Link id="go_biz_demo" className="header-btn header-btn-common j-to-biz-demo-click" to="/contact-us">Contact Sales</Link>
                        <a id="go_contact_no" className="header-btn header-btn-common j-to-biz-demo-click" data-type="headerNav-goContactUs" href="/contact-us/" data-gatype="Contact_Us" rel="nofollow">+919990588144/99</a>
                    </div>
                    <div className="btn-box right-btn-box">
                        <div className="sign-box">
                            {/* <a className="header-btn header-btn-sign j-to-header-ga-click" href="/contact-us" data-type="Bartop headerNav-goSignin" data-gatype="Signin" rel="nofollow">
                                <span className="btn-txt">Login</span>
                            </a> */}
                            {
                                currentUser
                                ?
                                <Link id="login" className="header-btn header-btn-sign j-to-header-ga-click" to="/" data-type="Bartop headerNav-goSignin" data-gatype="Signin" onClick={logout}>
                                    {/* <span className="btn-txt">{currentUser.firstName}</span> */}
                                    <span className="btn-txt">Logout</span>
                                </Link>
                                :
                                <Link id="login" className="header-btn header-btn-sign j-to-header-ga-click" to="/login" data-type="Bartop headerNav-goSignin" data-gatype="Signin">
                                    <span className="btn-txt">Login</span>
                                </Link>
                            }
                           
                            <a className="header-btn header-btn-signup j-to-header-ga-click i-hide" href="/user-center/signup/" data-type="headerNav-goSignup" data-gatype="Signup" rel="nofollow">
                                <span className="btn-txt">Sign up</span>
                            </a>
                        </div>
                        <div className="account-box i-hide">
                            <div className="item-profile-info">
                                <a id="profile_username_link" className="header-btn item-profile-link" href="javascript:void(0)" rel="nofollow"></a>
                            </div>
                            <div className="item-dropdown item-profile-actions">
                                <ul className="item-dropdown-list">
                                    <li className="item-dropdown-item item-profile-action-user-center">
                                        <a href="/user-center/" rel="nofollow">User center</a>
                                    </li>
                                    <li className="item-dropdown-item item-profile-action-signout">
                                        <a href="javascript:;" rel="nofollow">Sign Out</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="public-header-sm lang_en bg biz" id="public-header-sm">
                <div className="nav-head">
                    <a className={mobileMenu ?
                        "menu-btn j-navbar-head navbar-toggle"
                        : "menu-btn j-navbar-head"
                    } href="javascript:void(0)"
                        onClick={openMobileMenu}>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                        <span className="icon-bar"></span>
                    </a>
                    <div className="logo-box">
                        <a href="/" className="item-logo biz-logo"></a>
                    </div>
                </div>
                <div className={mobileMenu ?
                    "nav-box j-navbar-box status-open"
                    : "nav-box i-hide j-navbar-box"
                }>
                    <ul className="navs-list">
                        <li className="navs-list-item j-more-nav product-nav-wrap" data-type="headerNav-product">
                            <a>
                                <span style={{ position: "relative" }}>
                                    About Us<i className="nav-pro-new-point" style={{ display: "block" }}></i>
                                </span>
                            </a>
                        </li>
                        <li className="navs-list-item j-more-nav solution-nav-wrap" data-type="headerNav-solution">
                            <a onClick={solution_option}>
                                <span style={{ position: "relative" }}>
                                    Solutions<i className="nav-sol-new-point" style={{ display: "block" }}></i>
                                </span>
                            </a>
                            <span className="more-arrow"></span>
                            <div className="j-more-content">
                                <ul>
                                    <li className="more-content-item j-more-nav" data-type="headerNav-/business/?from=solution">
                                        {/* <a className="more-header-title">Mobile device management &amp;remote access </a> */}
                                        <span className="more-arrow"></span>
                                        <ul className="more-body-list j-more-content hide"  id="solution_option">
                                            <li className="more-body-item hover-effect" data-type="headerNav-/business/?from=solution">
                                                <a href="/business/?from=solution" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business">Overview</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/digital-signage/">
                                                <a href="/mdm-solution/digital-signage/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Signage">Digital Signage &amp;Multimedia</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/it-service-provider/">
                                                <a href="/mdm-solution/it-service-provider/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_IT">IT &amp;MSP Services</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/unattended-devices/">
                                                <a href="/mdm-solution/unattended-devices/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Unattend">Unattended Devices</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/logistics-and-transportation/">
                                                <a href="/mdm-solution/logistics-and-transportation/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Logistic">Transportation &amp;Logistics</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/communication/">
                                                <a href="/mdm-solution/communication/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Communication">Communications</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/education/">
                                                <a href="/mdm-solution/education/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Education">Education</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/healthcare/">
                                                <a href="/mdm-solution/healthcare/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Healthcare">Healthcare</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/smb/">
                                                <a href="/mdm-solution/smb/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_SMB">Small-Scale Deployment (10-100)</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-/mdm-solution/enterprise/">
                                                <a href="/mdm-solution/enterprise/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_Business_Enterprise">Large-Scale Deployment (100+)</a>
                                            </li>
                                        </ul>
                                    </li>
                                    {/* <li className="more-content-item j-more-nav" data-type="headerNav-/remote-support-for-teams/">
                                        <a className="more-header-title">Mirror 2050 Remote Support </a>
                                        <span className="more-arrow"></span>
                                        <ul className="more-body-list j-more-content hide">
                                            <li className="more-body-item hover-effect" data-type="headerNav-/remote-support-for-teams/">
                                                <a href="/remote-support-for-teams/" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_RS_Business">Overview</a>
                                            </li>
                                            <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-2">
                                                <a href="/remote-support-for-teams/#rs-solution-item-2" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_RS_Business_Hardware">Hardware manufacturers</a>
                                            </li>
                                            <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-3">
                                                <a href="/remote-support-for-teams/#rs-solution-item-3" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_RS_Business_IT">IT system integrators and software providers</a>
                                            </li>
                                            <li className="more-body-item hover-effect header-Subpage" data-type="headerNav-/remote-support-for-teams/#rs-solution-item-1">
                                                <a href="/remote-support-for-teams/#rs-solution-item-1" target="_self" className="j-to-header-ga-click more-body-title" data-gatype="Solution_RS_Business_Inhouse">In-house IT support team</a>
                                            </li>
                                        </ul>
                                    </li> */}
                                    {/* <li className="more-content-item j-more-nav" data-type="headerNav-ai-content:/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                        <a className="more-header-title">
                                            ChatInsight.AI <span className="more-header-tip"></span>
                                        </a>
                                        <span className="more-arrow"></span>
                                        <ul className="more-body-list j-more-content hide">
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">Overview</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/employee-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/solutions/employee-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">Internal Employee Support</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/customer-service-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/solutions/customer-service-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">Success Customer Support</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/it-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/solutions/it-support-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">IT Technical Support</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/solutions/marketing-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/solutions/marketing-chatbot/?utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">Marketing Conversational Assistant</a>
                                            </li>
                                            <li className="more-body-item hover-effect" data-type="headerNav-ai-content:/?anchor=empower&amp;tab_key=5&amp;utm_source=topbar&amp;utm_medium=Mirror 2050com">
                                                <a href="https://www.chatinsight.ai/?anchor=empower&amp;tab_key=5&amp;utm_source=topbar&amp;utm_medium=Mirror 2050com&amp;lang=en" target="_blank" className="j-to-header-ga-click more-body-title" data-gatype="">Sales Support Assistant</a>
                                            </li>
                                        </ul>
                                    </li> */}
                                </ul>
                            </div>
                        </li>
                        {/* <li className="navs-list-item j-more-nav" data-type="headerNav-/pricing/" data-href="redirect-pricing">
                            <a href="/blog">
                                <span style={{position: "relative"}}>
                                    Blog<i className="nav-res-new-point" style={{display: "block"}}></i>
                                </span>
                            </a>
                        </li> */}
                        <li className="navs-list-item j-more-nav" data-type="headerNav-resource">
                            <a href="/contact-us">
                                <span style={{ position: "relative" }}>
                                    Contact Us<i className="nav-res-new-point" style={{ display: "block" }}></i>
                                </span>
                            </a>
                        </li>
                        <li className="navs-list-item j-more-nav" data-type="headerNav-resource">
                            <a href="/pricing">
                                <span style={{ position: "relative" }}>
                                    Pricing<i className="nav-res-new-point" style={{ display: "block" }}></i>
                                </span>
                            </a>
                        </li>
                        <li className="navs-list-item" data-type="headerNav-/download/" data-href="redirect-download">
                            <a role="button" download="Mirror_2050.apk" href="/Mirror2050.apk" >
                                <span className="blink_me" style={{ position: "relative" }}>
                                    Download Now<i className="nav-res-new-point" style={{ display: "block" }}></i>
                                </span>
                            </a>
                        </li>
                    </ul>
                    <div className="btn-box">
                        <a className="btn-plain j-to-header-ga-click" href="/contact-us/" data-gatype="Contact_Us" rel="nofollow">Contact Us </a>
                        <a id="go_biz_demo" className="btn-plain j-to-biz-demo-click blink_me" data-gatype="Bartop" rel="nofollow" download="Mirror_2050.pdf" href="/know_more.pdf">Know More</a>
                        <a id="go_biz_demo" className="btn-plain j-to-biz-demo-click" data-gatype="Bartop" href="javascript:;" rel="nofollow">+919990588144/99</a>
                    </div>
                    <div className="btn-sign-box">
                        <a className="btn-signin" href="/login">Sign in </a>
                        <a className="btn-signup i-hide" href="/user-center/signup/">Sign up</a>
                    </div>
                    <div className="user-info-wrapper i-hide">
                        <a href="/user-center/">
                            <div className="user-info-box j-user-info-box">
                                <img className="user-info-avatar lazyload" src="https://cdn1.Mirror 2050.com/assets/img/header/user_default_photo-fe4db896c6.jpg" style={{ width: "40px", height: "40px" }} alt="sign in Mirror 2050 account" />
                                <div className="user-info j-user-info">
                                    <h4 className="user-info-name"></h4>
                                    <p className="user-info-email"></p>
                                </div>
                                <span className="more-arrow"></span>
                            </div>
                        </a>
                        <div className="btn-box">
                            <a className="btn-plain item-profile-action-signout" href="javascript:;">Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal fade" id="comingSoonModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Download</h5>
                            <button type="button" class="close bg-transparent" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body h1">
                            Coming Soon
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;