import React from "react";
import '../../styles.css';
import './PanelList.scss'
import content1 from '../../assets/content_1.jpg'
import content2 from '../../assets/content_2.jpg'
import content3 from '../../assets/content_3.jpg'


function PanelList() {
    return (
        <ul className="panel-list">
            <li className="panel-item type-1">
                <div className="content-box">
                    <h2 className="panel-title">Screen and control advanced separates one spot </h2>
                    <p className="panel-txt">Effectively monitor your unattended and static Android computerized signage’s from an electronic dashboard. Mirror 2050shows the home screen of each and every signage so you can rapidly see which ones are not working as expected.</p>
                    <ul className="panel-content-list">
                    </ul>
                    <a href="/business/member-management-and-data-security/" className="panel-content-btn">Learn more about Management &amp;Security</a>
                </div>
                <div className="img-box">
                    <img src={content1} alt="" srcset="" />
                </div>
            </li>
            <li className="panel-item type-1">
                <div className="img-box">
                    <img src={content2} alt="" srcset="" />
                </div>
                <div className="content-box">
                    <h2 className="panel-title">Remotely see signage screen and investigate whenever</h2>
                    <p className="panel-txt">•	Mirror 2050 allows you from a distance to get to gadgets and resolve specialized mistakes without leaving your office.</p>
                    <ul className="panel-content-list">
                        <li className="panel-content-item">Kill the requirement for regular on location visits and superfluous return of gadgets by remotely investigate issues</li>
                        <li className="panel-content-item">Screen gadget screens and spot issues ahead of schedule to limit the effect brought about by gadget free time</li>
                    </ul>
                    <a href="/business/unattended-remote-access-and-control/" className="panel-content-btn">Learn more about Remote Access</a>
                </div>
            </li>
            <li className="panel-item type-1">
                <div className="content-box">
                    <h2 className="panel-title">Transform your Android gadgets into advanced signage</h2>
                    <p className="panel-txt">You can turn any Android-based telephones, tablets or gadgets into an intelligent computerized signage or data booth. Assemble drawing in experience for your clients and lift deals with more brand presence.</p>
                    <ul className="panel-content-list">
                        <li className="panel-content-item">Get gadgets into advanced announcements for data or news show</li>
                        <li className="panel-content-item">Alter interface appearance to bring together brand presence across gadgets</li>
                        <li className="panel-content-item">Establish a solid and intuitive promoting climate through site whitelisting.</li>
                    </ul>
                    <a href="/business/kiosk-lockdown-software/" className="panel-content-btn">Learn more about Kiosk Mode</a>
                </div>
                <div className="img-box">
                    <img src={content3} alt="" srcset="" />
                </div>
            </li>
        </ul>
    );
}

export default PanelList;