import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../../styles.css';
import './PricingMain.scss';
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { cashfree } from '../../cashfree/util';

import { environment } from '../../environment';

function PricingMain() {
    const [data, setData] = useState([]);
    const [currentUser, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem('user')));
    const [show, setShow] = useState(false);
    const [showInfo, setInfoShow] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [memberCount, setMemberCount] = useState(0);
    const [memberDropDown, setMemberDropDown] = useState([]);


    const handleClose = () => setShow(false);
    const handleInfoClose = () => setInfoShow(false);

    function handleShow(plan) {
        if (currentUser) {
            setSelectedPlan(plan)
            setShow(true);
            setMemberDropDown(plan.device)
        } else {
            window.location.href = '/login';
        }
    }

    function getSessionId() {
        var date = new Date();

        let newExpiredDate = date.setDate(date.getDate() + 30);
        let param = {
            plan: selectedPlan.id,
            subscription_end: newExpiredDate.toString(),
            getUser: 'true',
            user: currentUser.id,
            memberCount: memberCount.toString(),
            price: (selectedPlan.price * memberCount).toString()
        }

        let config = {
            headers: {
                token: sessionStorage.getItem('token'),
            }
        }

        const instance = axios.create({
            baseURL: environment.baseURL,
        });
        instance.post('/payment/generate_order', param, config).then(async (res) => {
            handlePayments(res.data.data)
            console.log(res)
        });
    }

    function handlePayments(paymentSessionId) {
        let checkoutOptions = {
            paymentSessionId,
            returnUrl: window.location.origin+ "/pricing?myorder={order_id}",
        }
        cashfree.checkout(checkoutOptions).then(function (result) {
            if (result.error) {
                alert(result.error.message)
            }
            if (result.redirect) {
                console.log("Redirection")
            }
        });
    }

    function purchasePlan(data) {

        setLoading(true);
        let param = {
            plan: data.plan,
            subscription_end: parseInt(data.subscription_end),
            getUser: data.getUser === 'true' ? true : false,
            user: data.user,
            memberCount: parseInt(data.memberCount),
            order_id: data.order_id
        }

        let config = {
            headers: {
                token: sessionStorage.getItem('token'),
            }
        }

        const instance = axios.create({
            baseURL: environment.baseURL
        });
        instance.post('/subscription', param, config).then(async (res) => {
            if (res.data.data.user.length) {
                sessionStorage.setItem('user', JSON.stringify(res.data.data.user[0]));
                setCurrentUser(res.data.data.user[0]);
                window.location.href = '/pricing';
            }
            handleClose()
            setLoading(false);
            setInfoShow(true)
        });
    }

    function onSelectChange(event) {
        setMemberCount(event.target.value)
    }

    useEffect(() => {
        console.log('i fire once');

        async function fetchData() {
            const instance = axios.create({
                baseURL: environment.baseURL,
            })
            instance.get('/plan_details').then(async (res) => {
                setData(res.data.data.plan)
            })
        }
        fetchData();


        const queryParams = new URLSearchParams(window.location.search)
        const order_id = queryParams.get("myorder");

        if (order_id) {
            let config = {
                headers: {
                    token: sessionStorage.getItem('token'),
                }
            }

            const instance = axios.create({
                baseURL: environment.baseURL,
            });
            instance.post('/payment/check', { order_id }, config).then(async (res) => {
                console.log(res)
                if (res.data.success) {
                    purchasePlan(res.data.data)
                }
            });
        }


    }, [])

    return (
        <div className="pricing-home">
            <div className="pricingbanner">
                <div className="com-container">
                    <h1 className="big-title">Plan &amp; Pricing for Mirror2050 Products &amp; Solutions</h1>
                </div>
            </div>
            <section className="category-wrap">
                <div className="category">
                    <div className="com-container">
                        <div className="cata-menu-content" categorytype="pc">
                            <div className="cata-lists first-lists">
                                <div className="top">
                                    <p className="cata-title">Business Products</p>
                                </div>
                                <div className="bottom">
                                    <ul className="list-ul">
                                        <li className="list-li">
                                            <a href="#biz" className="box j-goto-business-anchor j-goto-anchor">
                                                {/* <img  className="icon" src="https://img-1-cdn.airdroid.com/assets/img/pricing/home/ic_logo_biz@2x-07c2d27a74.png" alt="Mirror2050 Business"/> */}
                                                <p className="cata-text"> Mirror Business </p>
                                            </a>
                                        </li>
                                        <li className="list-li">
                                            <a href="#rs" className="box j-goto-business-anchor j-goto-anchor">
                                                {/* <img  className="icon" src="https://img-2-cdn.airdroid.com/assets/img/pricing/home/ic_logo_biz_rs@2x-9f2f03c6c3.png" alt="Mirror2050 Remote Support (Business)"/> */}
                                                <p className="cata-text">Business Support</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="cata-lists second-lists">
                                <div className="top">
                                    <p className="cata-title">Personal Products</p>
                                </div>
                                <div className="bottom">
                                    <ul className="list-ul">
                                        <li className="list-li">
                                            <a href="#personal" className="box j-goto-personal-anchor j-goto-anchor">
                                                {/* <img  className="icon" src="https://img-3-cdn.airdroid.com/assets/img/pricing/home/ic_logo_personal@2x-d37cee494a.png" alt="Mirror2050 Personal"/> */}
                                                <p className="cata-text">Mirror2050 Personal</p>
                                            </a>
                                        </li>
                                        <li className="list-li">
                                            <a href="#cast" className="box j-goto-personal-anchor j-goto-anchor">
                                                {/* <img  className="icon" src="https://img-4-cdn.airdroid.com/assets/img/pricing/home/ic_logo_cast@2x-c6c4553a2f.png" alt="Mirror2050 Cast"/> */}
                                                <p className="cata-text">Mirror2050 Cast</p>
                                            </a>
                                        </li>

                                        <li className="list-li">
                                            <a href="#personalRs" className="box j-goto-personal-anchor j-goto-anchor">
                                                {/* <img  className="icon" src="https://img-1-cdn.airdroid.com/assets/img/pricing/home/ic_logo_rs_personal@2x-dc7f566848.png" alt="Mirror2050 Remote Support (Personal)"/> */}
                                                <p className="cata-text">Mirror2050 Personal</p>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="mobile-category">
                    <div className="com-container">
                        <div className="mobile-cata-menu-content" categorytype="mobile">
                            <div className="mobile-cata-menu-item first active">
                                <div className="item-cata-title">
                                    <div className="left-box">
                                        <span className="shuxian"></span>
                                        <h3 className="mob-cata-title">Business Products</h3>
                                    </div>
                                    <span className="more-icon"></span>
                                </div>
                                <ul className="item-cata-listul" style={{ "display": "block" }}>
                                    <li className="list-li">
                                        <a href="#biz" className="box j-goto-anchor active">
                                            <span className="cata-text">Mirror2050 Business</span>
                                        </a>
                                    </li>
                                    <li className="list-li">
                                        <a href="#rs" className="box j-goto-anchor">
                                            <span className="cata-text">Mirror2050 Remote Support (Business)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="mobile-cata-menu-item second">
                                <div className="item-cata-title">
                                    <div className="left-box">
                                        <span className="shuxian"></span>
                                        <h3 className="mob-cata-title">Personal Products</h3>
                                    </div>
                                    <span className="more-icon"></span>
                                </div>
                                <ul className="item-cata-listul">
                                    <li className="list-li">
                                        <a href="#personal" className="box j-goto-anchor">
                                            <span className="cata-text">Mirror2050 Personal</span>
                                        </a>
                                    </li>
                                    <li className="list-li">
                                        <a href="#cast" className="box j-goto-anchor">
                                            <span className="cata-text">Mirror2050 Cast</span>
                                        </a>
                                    </li>
                                    <li className="list-li">
                                        <a href="#parental" className="box j-goto-anchor">
                                            <span className="cata-text">Mirror2050 Parental Control</span>
                                        </a>
                                    </li>
                                    <li className="list-li">
                                        <a href="#personalRs" className="box j-goto-anchor">
                                            <span className="cata-text">Mirror2050 Remote Support (Personal)</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="business-category-container">
                <div className="com-container">
                    <div id="biz" className="item-cata-div business-category-content biz">
                        <div className="top-title-part">
                            <div className="logo-title">
                                <img src="https://img-2-cdn.airdroid.com/assets/img/pricing/home/ic_price_logo_biz@2x-00c89c5424.png" alt="Mirror2050 Business" />
                                <h3 className="com-title">Mirror2050 Business</h3>
                            </div>
                            <p className="com-text">Fully Featured Mobile Device &amp; Endpoints Management Solution</p>
                            <div className="special-text-box">
                                <p className="special-text">10% OFF on plans with 100+ devices. Contact our sales representative for additional discount for large-scale purchase.</p>
                            </div>
                        </div>
                        <div className="pricing-compare-box">
                            <div className="left">
                                <ul className="pricing-compare-ul">
                                    {
                                        data.map((plan) => {
                                            return (<li className="pricing-compare-li" key={plan.id}>
                                                <div className="pricing-compare-div">
                                                    <div className="desc-box">
                                                        <h4 className="desc-title">{plan.name} {currentUser?.subscription?.plan?.id == plan?.id ? <span className="current_plan">current</span> : ''}</h4>
                                                        <p className="desc-text">
                                                            {
                                                                plan.features.map((item) => {
                                                                    return <span key={item.name}>• {item.name} <br /></span>
                                                                })
                                                            }
                                                            <span className="small-desc"></span>
                                                        </p>
                                                    </div>
                                                    <div className="bottom">
                                                        <div className="price-box">
                                                            {/* <p  className="text">less than</p> */}
                                                            <div className="price">
                                                                <span className="unit">₹</span>
                                                                <p className="num">{plan.price}</p>
                                                                {/* <p  className="text">per device/year</p> */}
                                                            </div>
                                                        </div>
                                                        {!currentUser?.subscription?.plan?.id ?
                                                            <div className="btn-box">
                                                                <div className="has-bg-btn btns">
                                                                    <Link className="j-goto-contact j-handleAsk btn-item" role="button" data-id="1" onClick={() => handleShow(plan)}>
                                                                        <span>Buy Now</span>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            : <br />
                                                        }
                                                    </div>
                                                </div>
                                            </li>)
                                        })
                                    }
                                </ul>
                                {/* <div  className="bottom">
                                    <div  className="btn-box">
                                        <div  className="has-bg-btn btns">
                                            <Link  className="j-goto-contact j-handleAsk btn-item" data-id="1" to="/contact-us/">
                                                <span>Contact sales</span>
                                            </Link>
                                        </div>
                                        <div  className="has-border-btn btns">
                                            <a href="#"  className="j-goto-see-plan btn-item">View Details</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleInfoClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Choose Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {isLoading ? <div className="loader"></div> :
                            <select className="form-select" onChange={onSelectChange}>
                                <option defaultValue>Select</option>
                                {
                                    memberDropDown.map((item) => {
                                        return (<option value={item}>{item}</option>)
                                    })
                                }
                            </select>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    {isLoading ? '' :
                        <Button variant="primary" onClick={getSessionId}>
                            Buy
                        </Button>
                    }
                </Modal.Footer>
            </Modal>


            <Modal show={showInfo} onHide={handleInfoClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Members created successfully! Please check your email for their credentials

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleInfoClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default PricingMain;