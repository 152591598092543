import Footer from '../components/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import '../styles.css'
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert } from 'react-bootstrap';
const Login = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [currentUser, setCurrentUser] = useState(JSON.parse(sessionStorage.getItem('user')));

    const [error, setError] = useState(null)
    const [variant, setVariant] = useState('error')
    const instance = axios.create({
        baseURL: 'https://api.mirror2050.com',
        // baseURL: 'http://localhost:1337',
        timeout: 1000,
    })

    if(currentUser) {
        window.location.href = '/';
    }

    const handleSubmit = (e) => {
        setError(null);
        e.preventDefault();
        console.log(e, email, password);

        instance.post('/admin_login', { email, password, role: 'ADMIN' }).then((res) => {
            if(res.data.success) {
                sessionStorage.setItem('token', res.data.data.token);
                sessionStorage.setItem('user', JSON.stringify(res.data.data.user));
                window.location.href = '/';
            }
            console.log(res);
            setVariant('success')
            setError('Successful!')
            setEmail('')
            setPassword('')
        })
            .catch((err) => {
                setVariant('error')
                if (err.response && err.response.data) {
                    setError(err.response.data.message)
                } else {
                    setError(err.message)
                }
                console.error(err);
            })

    }

    return (
        <>
            <Navbar />
            <div className="body-wrapper mt-5 pt-3">
                <div className="dnd-content-area compass__event">
                    <div className="body-container-wrapper">
                        <div className="body-container">
                            <div className="container-fluid body-section compass__event--banner dark">
                                <Alert variant={variant} show={!!error}>
                                    {error}
                                </Alert>
                                <div className="row-fluid-wrapper background-login">
                                    <div className="row-fluid" height="7">
                                        <div className='row login-background'>
                                            <div className="col-md-7 text-center">
                                                <img width="300px" src='logo.png' /><br/>
                                                <img width="350px" src='logo-text.png' />
                                            </div>
                                            <div className='col-md-5'>
                                                <div className='card m-5 p-5 login-card' >
                                                    <form onSubmit={handleSubmit} accept-charset="UTF-8">
                                                        <div className="mb-3">
                                                            <label for="exampleInputEmail1" className="form-label m-0">Email</label>
                                                            <input value={email} onChange={(event) => setEmail(event.target.value)} type="email" className="form-control input-box" id="exampleInputEmail1" required />
                                                        </div>
                                                        <div className="mb-3">
                                                            <label for="exampleInputPassword1" className="form-label m-0">Password</label>
                                                            <input value={password} onChange={(event) => setPassword(event.target.value)} type="password" className="form-control input-box" id="exampleInputPassword1" required />
                                                        </div>
                                                        <div className='text-center'>
                                                            <button type="submit" className="btn btn-primary w-100 mb-2 text-dark fw-bolder">Login</button><br />
                                                            <a href="/register" role="button" className=" w-100 text-dark fw-bolder">Register</a>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};
export default Login;