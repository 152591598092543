import React, { useEffect, useRef  } from "react";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import '../../styles.css';
import './Comments.scss';
import { useState } from 'react';
import testimonial1 from '../../assets/testimonial_1.jpg'
import testimonial2 from '../../assets/testimonial_2.jpg'

function Comments() {
    const swiperRef = useRef(null);

    useEffect(() => {
        swiperRef.current = new Swiper('.swiper-container', {
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);

    const slideNext = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    const slidePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };
    return (
        <div className="ct-panel type-3">
            <h2 className="panel-title">Hear from our customers</h2>
            <div className="panel-wrap">
                <div className="swiper-container panel-box">
                    <div className="swiper-wrapper">
                    <div className="swiper-slide">
                            <div className="img-box">
                                <img src= {testimonial1} alt="Access device at distance" />
                                <div className="tab-btn"></div>
                            </div>
                            <div className="panel-content-box">
                                {/* <div className="avatar-box">
                                    <img src="https://img-2-cdn.airdroid.com/assets/img/common/partner_logo/logo_aji_digital-f80bc9986a.png" alt="" />
                                </div> */}
                                <p className="panel-content-title">"The arrangement has assisted us a ton with tackling the serious issue of following movement of our ground staff. Which has really helped us in following far off gadget right away, which has saved our assets time, traveling cost and keeping record of our group"</p>
                                {/* <p className="panel-content-txt">AMANDA STEELE, CEO</p>
                                <p className="panel-content-txt">Zensys Solutions</p> */}
                            </div>
                        </div>
                        <div className="swiper-slide">
                            <div className="img-box">
                                <img src= {testimonial2} alt="remote management of digital signage" />
                                <div className="tab-btn"></div>
                            </div>
                            <div className="panel-content-box">
                                {/* <div className="avatar-box">
                                    <img src="https://img-2-cdn.airdroid.com/assets/img/common/partner_logo/logo_aura-2c2b687313.png" alt="" />
                                </div> */}
                                <p className="panel-content-title">"Can halfway track the area message pop-up, including pushing updates and following our staff movement. Speeds up the help for our clients and helps in holding the expert connection."</p>
                                {/* <p className="panel-content-txt">Roy Page, Product Specialist</p> */}
                                {/* <p className="panel-content-txt">Swiftware</p> */}
                            </div>
                        </div>
                    </div>
                    <div className="swiper-button-prev panel-prev-custom" onClick={slidePrev}></div>
                    <div className="swiper-button-next panel-next-custom" onClick={slideNext}></div>
                </div>
                <div className="swiper-pagination panel-pagination"></div>
            </div>
        </div>
    );
}

export default Comments;
