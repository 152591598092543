import React from "react";
import '../../styles.css';
import './OutroPanel.scss'
import { Link } from "react-router-dom";

function OutroPanel() {
    return (
        <div className="panel-start">
            <h2 className="panel-title">Remotely screen and access your advanced screens whenever</h2>
            <div className="btn-box">
                <Link to="/schedule-a-demo" className="btn j-biz-apply-bottom">Schedule a Demo</Link>
                <Link to="/contact-us" className="btn btn-hw">Contact Sales</Link>
            </div>
        </div>
    );
}

export default OutroPanel;