import React from "react";
import '../../styles.css';
import './FloatingButton.scss'
import WhatsappLogo from "../../assets/whatsapp-logo.png"

function FloatingButton() {
    return (
        <div className="footer-side biz" style={{bottom: "5vh"}}>
            <a href="https://wa.me/919990588199" target="_blank" rel="noreferrer">
                <div className="side-box side-phone">
                    <div className="side-icon-box flex justify-center align-center j-open">
                        <img className="side-icon" src={WhatsappLogo} alt="WhatsApp" />
                    </div>
                    <div className="contact-detail-wrap sale-box">
                        <button className="close-icon j-close"></button>
                        <a className="sale-box-main-txt" href="wa:+1646-878-6499">+1 646 878 6499</a>
                        <p className="tip-txt">(New York)</p>
                    </div>
                </div>
            </a>
        </div>
    );
}

export default FloatingButton;