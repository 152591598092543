import React, { useEffect, useState } from "react";
import '../../styles.css';
import './ContactUsForm.scss'
import axios from "axios";
import { Alert, Toast } from "react-bootstrap";
// import $ from 'jquery'; 

function ContactUsForm() {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [industry, setIndustry] = useState('')
    const [deviceCount, setDeviceCount] = useState(0)
    const [error, setError] = useState(null)
    const [variant, setVariant] = useState('error')

    const instance = axios.create({
        baseURL: 'https://api.mirror2050.com',
        timeout: 1000,
    })

    const handleSubmit = (e) => {
        setError(null);
        e.preventDefault();
        console.log(e, name, email, phone, industry, deviceCount);

        instance.post('/enquiry-requests', { name, email, phone, industry, deviceCount }).then((resp) => {
            console.log(resp);
            setVariant('success')
            setError('Successful!')
            setName('')
            setEmail('')
            setPhone('')
            setIndustry('')
            setDeviceCount(0);
            // $('#contactUsThankYouModal').on('shown.bs.modal', function () {
            //     console.log('Modal is shown!');
            //   });
            document.getElementById('contactUsThankYouModalButton').click()

        })
            .catch((err) => {
                setVariant('error')
                if (err.response && err.response.data) {
                    setError(err.response.data.message)
                } else {
                    setError(err.message)
                }
                console.error(err);
            })

    }

    return (
        <>

            <div id="hbspt-form-e0323ae8-c9ea-4360-8fb3-ef1eae81be76" className="hbspt-form" data-hs-forms-root="true">
                <Alert variant={variant} show={!!error}>
                    {error}
                </Alert>

                <form onSubmit={handleSubmit} className="hs-form-private hs-form stacked hs-custom-style">
                    <fieldset className="form-columns-1">
                        <div className="hs_full_name hs-full_name hs-fieldtype-text field hs-form-field">
                            <label id="label-full_name" placeholder="Enter your Full name" for="full_name">
                                <span>Full name</span>
                                <span className="hs-form-required">*</span>
                            </label>
                            <legend className="hs-field-desc" style={{ "display": "none" }}></legend>
                            <div className="input">
                                <input id="full_name" name="name" value={name} onChange={(event) => setName(event.target.value)} required placeholder="e.g. Thomas Hilt" type="text" className="hs-input" />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-columns-1">
                        <div className="hs_email hs-email hs-fieldtype-text field hs-form-field">
                            <label id="label-email" placeholder="Enter your Business email" for="email">
                                <span>Email</span>
                                <span className="hs-form-required">*</span>
                            </label>
                            <legend className="hs-field-desc" style={{ "display": "none" }}></legend>
                            <div className="input">
                                <input id="email" name="email" value={email} onChange={(event) => setEmail(event.target.value)} required placeholder="e.g. thomashilt@airdroid.com" type="email" className="hs-input" autocomplete="email" />
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-columns-1">
                        <div className="hs_phone hs-phone hs-fieldtype-phonenumber field hs-form-field">
                            <label id="label-phone" placeholder="Enter your Phone number" for="phone">
                                <span>Phone number</span>
                                <span className="hs-form-required">*</span>
                            </label>
                            <legend className="hs-field-desc" style={{ "display": "none" }}></legend>
                            <div className="input">
                                <div className="hs-input hs-fieldtype-intl-phone">
                                    <input id="phone" type="text" value={phone} onChange={(event) => setPhone(event.target.value)} className="hs-input" autocomplete="tel" pattern="\d{10,10}" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-columns-1">
                        <div className="hs_industry__form_dropdown_ hs-industry__form_dropdown_ hs-fieldtype-select field hs-form-field">
                            <label id="label-industry__form_dropdown_" placeholder="Enter your Industry" for="industry__form_dropdown_">
                                <span>Industry</span>
                                <span className="hs-form-required">*</span>
                            </label>
                            <legend className="hs-field-desc" style={{ "display": "none" }}></legend>
                            <div className="input">
                                <select id="industry__form_dropdown" value={industry} onChange={(event) => setIndustry(event.target.value)} required className="hs-input is-placeholder" name="industry">
                                    <option disabled="" value="">Please Select</option>
                                    <option value="Digital Signage &amp; Media Advertising">Digital Signage &amp; Media Advertising</option>
                                    <option value="Education">Education</option>
                                    <option value="Finance &amp; Insurance">Finance &amp; Insurance</option>
                                    <option value="Food &amp; Beverage">Food &amp; Beverage</option>
                                    <option value="Information Technology &amp; Internet Services">Information Technology &amp; Internet Services</option>
                                    <option value="Point-of-Sale Systems">Point-of-Sale Systems</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Medical &amp; Healthcare">Medical &amp; Healthcare</option>
                                    <option value="Real Estate &amp; Construction">Real Estate &amp; Construction</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Parental">Parental</option>
                                    <option value="Set-top Boxes">Set-top Boxes</option>
                                    <option value="Smart Security Devices">Smart Security Devices</option>
                                    <option value="Telecommunication">Telecommunication</option>
                                    <option value="Transportation &amp; Logistics">Transportation &amp; Logistics</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="form-columns-1">
                        <div className="hs-dependent-field">
                            <div className="hs_no_of_devices hs-no_of_devices hs-fieldtype-select field hs-form-field">
                                <label id="label-no_of_devices" placeholder="Enter number of devices" for="no_of_devices">
                                    <span>Number of devices</span>
                                    <span className="hs-form-required">*</span>
                                </label>
                                <legend className="hs-field-desc" style={{ "display": "none" }}></legend>
                                <div className="input">
                                    <input type="number" value={deviceCount} onChange={(event) => setDeviceCount(event.target.value)} min={0} id="no_of_devices" required className="hs-input is-placeholder" name="deviceCount" />
                                </div>
                            </div>
                        </div>
                    </fieldset>
                    <div className="hs_submit hs-submit">
                        <div className="hs-field-desc" style={{ "display": "none" }}></div>
                        <div className="actions">
                            <input type="submit" className="hs-button primary large" value="Submit"/>
                        </div>
                    </div>
                </form>
            </div>
            <button type="button" class="btn btn-primary invisible" id="contactUsThankYouModalButton" data-toggle="modal" data-target="#contactUsThankYouModal">
            </button>

            <div class="modal fade" id="contactUsThankYouModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Thank You</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            Thank you for reaching out to us! A member of our team will get in touch with you shortly. We appreciate your interest and look forward to assisting you.
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            {/* <button type="button" class="btn btn-primary">Save changes</button> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactUsForm;