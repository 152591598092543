import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"

const RefundPolicy = () =>{
    return (
       <>
       <Navbar/>
       <br/><br/><br/><br/><br/>
       <p><strong>Refund Policy </strong></p>
<p>This refund policy was keep going refreshed on May 10, 2024.</p>
<p>All Refund demands for Mirror2050 will stick to this Refund Strategy.</p>
<p>Kindly read this approach cautiously prior to submitting a request on our site as it frames your privileges in regards to the product buy, including all limitations and prohibitions.</p>
<p>On the off chance that you buy Mirror2050 through an outsider affiliate and you need to have the money in question returned, you should apply for a Refund with the affiliate. Figure out additional insights regarding this here.</p>
<p><strong>General</strong></p>
<p>In the event that the product you bought has specialized issues that the Mirror2050 specialized help group can't determine, you might be qualified for a full Refund as per this Refund Strategy. Kindly note anyway that you are urged to acknowledge proficient assistance from our Client care Group to keep away from the extensive Refund application process. Contact Our Help Community through info@mirror2050.com</p>
<p><strong>Refund conditions </strong></p>
<p>Dependent upon the Refund conditions illustrated in this Refund Strategy and some other material regulations, you might be qualified for a full Refund in no less than 7 days after your buy as long as your Refund demand meets the accompanying circumstances:</p>
<p>Refund won't be given in the event that a Refund demand is made over 7 days after the underlying buy.</p>
<p>The Refund just applies to the essential mirror2050membership (the main buy).</p>
<p>Any extra mirror2050 memberships that you buy can't be Refunded in a similar solicitation.</p>
<p>You won't get the refund question assuming you lose the confidential encryption key which can bring about information misfortune, brief loss of admittance to your special client board, and a few different bothers.</p>
<p>You won't get refunded for any amount of money that is possible assuming you neglect to re-introduce or re-connect Mirror2050in the event that the product or the working framework on the objective gadget is refreshed.</p>
<p>You won't get the refund in question in the event that the objective gadget doesn't consent to the mirror2050 Similarity Strategy.</p>
<p>You won't get the refund in question on the off chance that the objective gadget loses association with the Mirror2050 membership because of the deficiency of a web association, a plant reset, or an update of the gadget's working framework.</p>
<p>You may likewise be ineligible for a Refund in the event that the reasons are outside of Mirror2050's reach. These reasons can incorporate, yet are not restricted to:</p>
<p>The objective gadget isn't possessed by you or the objective gadget isn't utilized by your kid or ward</p>
<p>The objective gadget doesn't have web access</p>
<p>In the event that you change the transporter administrator on the objective gadget, prompting the deficiency of web network on the gadget which can influence the capability of Mirror2050.</p>
<p>In the event that you update the working framework on the objective gadget</p>
<p>On the off chance that you reset the objective gadget to plant settings</p>
<p>In the event that you neglect to adhere to the establishment rules given by our client care group through email or call</p>
<p>Assuming you decline specialized help</p>
<p>On the off chance that you don't have actual admittance to the objective gadget or you have failed to remember the secret word to open it.</p>
<p>In the event that you had not gotten the information saved money on the objective gadget before Mirror2050 was introduced on it.</p>
<p>The objective gadget is running a working framework that Mirror2050 doesn't uphold. We support only android gadgets as if now.</p>
<p>You planned to utilize Mirror2050 on more than one gadget utilizing one membership when the membership doesn't consider Mirror2050 use in a few objective gadgets at the same time.</p>
<p>Individual reasons, including "I've adjusted my perspective," "I committed an error," and so on.</p>
<p>In the event that you can't or reluctant to escape (iOS) or root (Android) the objective gadget to get to cutting edge checking highlights (WhatsApp, Skype, Viber observing, and so on.)</p>
<p>On the off chance that Mirror2050 was de-connected from the gadget by hostile to infection programming, the objective gadget's proprietor or different administrations.</p>
<p>Outsiders' Items (Third parties)</p>
<p>Refund won't be utilized assuming the explanations behind the Refund demand are outside of Mirror2050's reach. These can incorporate yet are not restricted to;</p>
<p>Assuming you change transporters, causing the deficiency of web network which can influence the capability of the outsiders' items.</p>
<p>You neglect to keep the establishment rules of the outsider's client care group gave through email or call.</p>
<p>Individual reasons, including "I've adjusted my perspective," "I committed an error," and so forth.</p>
<p>In the event that the client doesn't execute or disregards the rules, guidelines, terms of purpose, permit understanding, as well as different arrangements, records, and proposals presented by the outsider supplier.</p>
<p><strong>Dragging out a Membership</strong></p>
<p>Non-refundable.</p>
<p><strong>Overhauls/Downsizes</strong></p>
<p>Refunds won't be given for overhauls and downsizes to or from a Mirror2050 membership plan.</p>
<p><strong>Refund Strategy</strong></p>
<p>You start a Refund follow through with by sending a Refund solicitation to info@mirror2050.com. The Refund solicitation will be handled as quickly as time permits. In the event that a Refund is given, 9% of the Refund sum will be deducted to cover bank and installment handling charges.</p>
<p><strong>Chargeback</strong></p>
<p>You concur that we will suspend admittance to all possible records that you have with us in case of a chargeback by a Mastercard organization or some other comparable activity by an installment supplier permitted by us in association with your membership buy. Account reactivation will be handled at our circumspection and solely after we get installment for neglected buys.</p>
<p>You likewise consent to bear the expense of any expenses that might be caused because of chargebacks or some other installment questions brought by you, your bank, or the installment supplier you are utilizing. Our ongoing restoration charges as per the norms.</p>
       <Footer/>
       </>   
    )
}

export default RefundPolicy