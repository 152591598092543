import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import PricingMain from "../components/Pricing/PricingMain";
const Pricing = () => {
    return (
        <>
            <Navbar />
            <div className="public-header-position"></div>
            <div className="solution-wrap">
                <PricingMain/>
                <Footer />

            </div>
        </>
    );
}

export default Pricing;