import React, { useEffect, useState } from "react";
import '../../styles.css';
import './Banner.scss'
import { Link } from "react-router-dom";
import banner from '../../assets/banner.jpg'
import { environment } from '.././../environment';

function Banner() {
    const [imageUrl, setImageUrl] = useState(null);
    const imageDescription = 'A dynamically fetched image';

    useEffect(() => {
        fetch(environment.baseURL + '/banner')
            .then(response => response.json())
            .then(data => setImageUrl(data.data));
    }, []);

    return (
        <div className="banner">
            <div className="bg-box">
                <img className="bg-lg" src={imageUrl} alt="manage android digital signage eveywhere" />
                <img className="bg-xs" src={imageUrl} alt="manage android digital signage eveywhere" />
                {/* <img className="bg-lg" url={imageUrl} description={imageDescription} />
                <img className="bg-xs" url={imageUrl} description={imageDescription} /> */}
            </div>
            {/* <div className="bread-link">
                <a href="/business/" className="bread-link-txt">Business Home </a>
                <span className="bread-link-txt">Digital Signage &amp;Multimedia</span>
            </div> */}
            <div className="banner-content-box">
                <h1 className="banner-title">Build Captivating Digital Display Experience with Mirror 2050</h1>
                <p className="banner-txt">Manage all your Android-based digital signage anywhere anytime</p>
                <div className="btn-box">
                    <Link to="/schedule-a-demo" className="btn j-biz-apply">Schedule Demo</Link>
                    <Link to="/contact-us" className="btn btn-primary biz">Contact Sales</Link>
                </div>
                <p className="banner-tip">
                    Try all features for free without any additional setup fees.
                    <Link to="/schedule-a-demo" className="book-demo-link j-to-biz-demo-click">Schedule a Demo &gt;</Link>
                </p>
            </div>
        </div>
    );
}

export default Banner;