import React, { useState, useEffect } from "react";
import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import FloatingButton from './components/FloatingButton/FloatingButton';
import SplashScreen from './components/SplashScreen/Splash';
import "./styles.css"
import ContactUs from './pages/ContactUs';
import ScheduleADemo from './pages/ScheduleADemo';
import Pricing from "./pages/Pricing";
import AboutUs from "./pages/AboutUs";
import TermsAndConditions from "./pages/TermsAndCondtions";
import RefundPolicy from "./pages/RefundPolicy";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Login from "./pages/Login";
import Register from "./pages/Register";



function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading for 4 seconds
    setTimeout(() => {
      setIsLoading(false);
    }, 4000);
  }, []);
  return isLoading ?(
    <SplashScreen />
  ):(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
        </Route>
        <Route path="/contact-us" element={<ContactUs />}>
        </Route>
        <Route path="/about-us" element={<AboutUs />}>
        </Route>
        <Route path="/schedule-a-demo" element={<ScheduleADemo />}>
        </Route>
        <Route path="/pricing" element={<Pricing />}>
        </Route>
        <Route path="/t-and-c" element={<TermsAndConditions />}>
        </Route>
        <Route path="/refund-policy" element={<RefundPolicy />}>
        </Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}>
        </Route>
        <Route path="/login" element={<Login />}>
        </Route>
        <Route path="/register" element={<Register />}>
        </Route>
      </Routes>
      
      <FloatingButton/>
    </BrowserRouter>
  );
}

export default App;
