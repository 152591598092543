import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer";
import "../components/PanelList/PanelList.scss";
import AboutUsBanner from "../components/AboutUsBanner/AboutUsBanner";
import banner from '../assets/testimonial_1.jpg';
import banner1 from '../assets/testimonial_2.jpg';
import banner3 from '../assets/content_1.jpg';
import banner4 from '../assets/content_2.jpg';

const AboutUs = () => {
  return (
    <>
      <Navbar/>
        <div className="public-header-position"></div>
        <div className="solution-wrap">
    
            <ul className="panel-list">
              <li className="panel-item type-1">
                  <div className="content-box">
                      <p className="panel-txt">Everything began with a dream to impact the world with imaginative innovation. Mirror 2050, a client driven organization with a dream of "Igniting Development, Energizing Advancement", fabricates programming pointed toward further developing efficiency for people and organizations, dealt with the primary Mirror 2050 application.</p>
                  </div>
                  <div className="img-box">
                      <img src={banner} alt="" srcset="" />
                  </div>
              </li>
              <li className="panel-item type-1">
                  <div className="img-box">
                      <img src={banner1} alt="" srcset="" />
                  </div>
                  <div className="content-box">
                      <p className="panel-txt">Mirror2050 changes how people and organizations work through strong and skillful Controller, and Remote Help arrangements, investigated and created utilizing over long stretches of information and ability. Mirror 2050 will be your driving supplier of programming and applications that will help you in accomplishing a more viable method for making, associate, and team up.</p>
                  </div>
              </li>
              <li className="panel-item type-1">
                  <div className="content-box">
                      <h2 className="panel-title">Grants and Acknowledgments</h2>
                      <p className="panel-txt">As one of the biggest and most confided in programming commercial centers across the globe, and different audits are crucial in choosing the best B2B answers for your business. In the new Reports, Mirror 2050 has been perceived as quite possibly of the best arrangement out in the market today.</p>
                  </div>
                  <div className="img-box">
                      <img src={banner3} alt="" srcset="" />
                  </div>
              </li>
              <li className="panel-item type-1">
                  <div className="img-box">
                      <img src={banner4} alt="" srcset="" />
                  </div>
                  <div className="content-box">
                      <p className="panel-txt">Aside from these, Mirror 2050 has gotten commendation and grant acknowledgments from media all over the planet. Furthermore, through this multitude of acknowledgments, our objective continues as before. To give our clients the most creative programming arrangements, designed to help you and your development.</p>
                  </div>
              </li>
            </ul>
            <Footer />


        </div> 
    </>
  )
};

export default AboutUs;