import React from "react";
import './Footer.scss';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <div className="public-footer lang_en j-public-footer-init hs-ct" style={{ position: "relative" }}>
            <div className="item-wrap">
                <div className="info-wrap">
                    <div className="info-container">
                        <p className="info-title">About Mirror 2050 Business</p>
                        <p className="info-content">Mirror 2050  is an undertaking grade cell phone management answer for joined in and unattended Android gadgets. It is intended to work on the portability and efficiency for organizations of all sizes through remote access, gadget checking, versatile application the board, and that's just the beginning.</p>
                    </div>
                </div>
                <div className="footer-body">
                    <ul className="footer-site-nav lg-show">
                        <li className="footer-nav-item">
                            <div className="dt">Company</div>
                            <Link className="link-txt j-to-footer-ga-click dd footer-privacy" to='/privacy-policy' data-gatype="Privacy_Policy" data-type="footerNav-privacy" rel="nofollow">Privacy Policy</Link>
                            <Link className="link-txt j-to-footer-ga-click dd footer-terms" to='/t-and-c' data-gatype="Terms_and_Conditions" data-type="footerNav-terms" rel="nofollow">Terms and conditions</Link>
                            <Link className="link-txt j-to-footer-ga-click dd footer-refund" to='/refund-policy' data-gatype="Refund_Policy" data-type="footerNav-refund" false="">Refund policy</Link>
                            <Link to="/contact-us" className="link-txt j-to-footer-ga-click dd footer-contact">Contact Sales</Link>
                            {/* <a className="link-txt j-to-footer-ga-click dd footer-about" href="#" data-gatype="About_Us" data-type="footerNav-about" target="_self" rel="nofollow">About us</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-blogs" href="#" data-gatype="Blogs" data-type="footerNav-blogs" target="_self" rel="nofollow">Blogs</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-downloads" href="#" data-gatype="Downloads" data-type="footerNav-downloads" target="_self" rel="nofollow">Downloads</a> */}
                        </li>
                        <li className="footer-nav-item">
                            <div className="dt">Resources</div>
                            {/* <a className="link-txt j-to-footer-ga-click dd footer-Resources" href="#" data-gatype="Resources_Library" data-type="footerNav-Resources" target="_self" rel="nofollow">Resource Library</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-Consulting" href="#" data-gatype="Consulting_Services" data-type="footerNav-Consulting" target="_self" rel="nofollow">Business Consulting Services</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-support" href="#" data-gatype="Resources_Help" data-type="footerNav-support" target="_blank">Help Center</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-how-to" href="#" data-gatype="Resources_Howto" data-type="footerNav-how-to" target="_blank">How-to</a> */}
                            {/* <a className="link-txt j-to-footer-ga-click dd footer-blog" href="#" data-gatype="Resources_Blog" data-type="footerNav-blog" target="_blank">Blog</a> */}
                            <Link to="/pricing" className="link-txt j-to-footer-ga-click dd footer-goPremium">Pricing</Link>
                            <a className="link-txt j-to-footer-ga-click dd footer-download" href="#" data-gatype="download" data-type="footerNav-download" target="_self">Download</a>
                        </li>
                        {/* <li className="footer-nav-item">
                            <div className="dt">Products</div>
                            <a className="link-txt j-to-footer-ga-click dd footer-personal" href="#" data-gatype="Product_Personal" data-type="footerNav-personal" target="_self">Mirror 2050 Personal</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-cast" href="#" data-gatype="Product_Cast" data-type="footerNav-cast" target="_self">Mirror 2050 Cast</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-business" href="#" data-gatype="Product_Business" data-type="footerNav-business" target="_self">Mirror 2050 Business</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-parentalcontrol" href="#" data-gatype="Product_Parental" data-type="footerNav-parentalcontrol" target="_self">Mirror 2050 Parental Control</a>
                            <a className="link-txt j-to-footer-ga-click dd footer-remotesupport" href="#" data-gatype="Product_RS" data-type="footerNav-remotesupport" target="_self">Mirror 2050 Remote Support</a>
                        </li> */}
                    </ul>
                    <ul className="footer-site-nav md-show">
                        <li className="footer-nav-item dl">
                            <a className="dt j-more-nav" href="javascript:void(0)" data-deep="1">
                                Company <span className="more-arrow"></span>
                            </a>
                            <div className="j-more-content">
                                {/* <div className="dd footer-about">
                                    <a className="link-txt" href="#" data-gatype="Resources_About" data-type="footerNav-about" target="_self" rel="nofollow">About Mirror2050</a>
                                </div> */}
                                {/* <div className="dd footer-contact">
                                    <Link to="/contact-us" className="link-txt">Contact Us</Link>
                                </div> */}
                                {/* <div className="dd footer-partner">
                                    <a className="link-txt" href="#" data-gatype="Partner" data-type="footerNav-partner" target="_self" false="">Partners</a>
                                </div> */}
                                {/* <div className="dd footer-eula">
                                    <a className="link-txt" href="#" data-gatype="Eula" data-type="footerNav-eula" target="_self" rel="nofollow">EULA</a>
                                </div> */}
                                <div className="dd footer-privacy">
                                    <Link className="link-txt j-to-footer-ga-click dd footer-privacy" to='/privacy-policy' data-gatype="Privacy_Policy" data-type="footerNav-privacy" rel="nofollow">Privacy Policy</Link>
                                </div>
                                <div className="dd footer-paymentTerms">
                                    <Link className="link-txt j-to-footer-ga-click dd footer-terms" to='/t-and-c' data-gatype="Terms_and_Conditions" data-type="footerNav-terms" rel="nofollow">Terms and conditions</Link>
                                </div>
                                <div className="dd footer-security">
                                    <Link className="link-txt j-to-footer-ga-click dd footer-refund" to='/refund-policy' data-gatype="Refund_Policy" data-type="footerNav-refund" false="">Refund policy</Link>
                                </div>
                                <div className="dd footer-security">
                                    <Link to="/contact-us" className="link-txt j-to-footer-ga-click dd footer-contact">Contact Sales</Link>
                                </div>
                                {/* <div className="dd footer-newsroom">
                                    <a className="link-txt" href="#" data-gatype="Newsroom" data-type="footerNav-newsroom" target="_self" rel="nofollow">Newsroom</a>
                                </div> */}
                            </div>
                        </li>
                        <li className="footer-nav-item dl">
                            <a className="dt j-more-nav" href="javascript:void(0)" data-deep="1">
                                Resources <span className="more-arrow"></span>
                            </a>
                            <div className="j-more-content">
                                {/* <div className="dd footer-Resources">
                                    <a className="link-txt" href="#" data-gatype="Resources_Library" data-type="footerNav-Resources" target="_self" rel="nofollow">Resource Library</a>
                                </div>
                                <div className="dd footer-Consulting">
                                    <a className="link-txt" href="#" data-gatype="Consulting_Services" data-type="footerNav-Consulting" target="_self" rel="nofollow">Business Consulting Services</a>
                                </div>
                                <div className="dd footer-support">
                                    <a className="link-txt" href="#" data-gatype="Resources_Help" data-type="footerNav-support" target="_blank">Help Center</a>
                                </div>
                                <div className="dd footer-how-to">
                                    <a className="link-txt" href="#" data-gatype="Resources_Howto" data-type="footerNav-how-to" target="_blank">How-to</a>
                                </div> */}
                                <div className="dd footer-blog">
                                    <a className="link-txt" href="#" data-gatype="Resources_Blog" data-type="footerNav-blog" target="_blank">Blog</a>
                                </div>
                                <div className="dd footer-goPremium">
                                    <Link to="/pricing" className="link-txt">Pricing</Link>
                                </div>
                                <div className="dd footer-download">
                                    <a className="link-txt" href="#" data-gatype="download" data-type="footerNav-download" target="_self">Download</a>
                                </div>
                            </div>
                        </li>
                        {/* <li className="footer-nav-item dl">
                            <a className="dt j-more-nav" href="javascript:void(0)" data-deep="1">
                                Products <span className="more-arrow"></span>
                            </a>
                            <div className="j-more-content i-hide">
                                <div className="dd footer-personal">
                                    <a className="link-txt" href="#" data-gatype="Product_Personal" data-type="footerNav-personal" target="_self">AirDroid Personal</a>
                                </div>
                                <div className="dd footer-cast">
                                    <a className="link-txt" href="#" data-gatype="Product_Cast" data-type="footerNav-cast" target="_self">AirDroid Cast</a>
                                </div>
                                <div className="dd footer-business">
                                    <a className="link-txt" href="#" data-gatype="Product_Business" data-type="footerNav-business" target="_self">AirDroid Business</a>
                                </div>
                                <div className="dd footer-parentalcontrol">
                                    <a className="link-txt" href="#" data-gatype="Product_Parental" data-type="footerNav-parentalcontrol" target="_self">AirDroid Parental Control</a>
                                </div>
                                <div className="dd footer-remotesupport">
                                    <a className="link-txt" href="#" data-gatype="Product_RS" data-type="footerNav-remotesupport" target="_self">AirDroid Remote Support</a>
                                </div>
                                <div className="dd footer-chatinsight">
                                    <a className="link-txt" href="#" data-gatype="Product_Ai" data-type="footerNav-chatinsight" target="_blank">ChatInsight.AI</a>
                                </div>
                            </div>
                        </li> */}
                    </ul>
                    <div className="item-social">
                        <div>
                            <ul className="item-social-list">
                                <li className="item-social-item">
                                    <a className="item-social-icon i_social_linkedin j-to-footer-ga-click lazyload" href="https://www.linkedin.com/company/mirror2050/" target="_blank" rel="nofollow noopener noreferrer" data-type="footerNav-LinkedIn" data-gatype="Linkedin">linkedIn</a>
                                </li>
                                <li className="item-social-item">
                                    <span className="item-social-splitter">/</span>
                                    <a className="item-social-icon i_social_twitter j-to-footer-ga-click lazyload" href="https://twitter.com/mirror2050" target="_blank" rel="nofollow noopener noreferrer" data-type="footerNav-goTwitter" data-gatype="Twitter">twitter</a>
                                </li>
                                <li className="item-social-item">
                                    <span className="item-social-splitter">/</span>
                                    <a className="item-social-icon i_social_facebook j-to-footer-ga-click lazyload" href="https://www.facebook.com/profile.php?id=61559331593978" target="_blank" rel="nofollow noopener noreferrer" data-type="footerNav-goFacebook" data-gatype="Facebook">facebook</a>
                                </li>
                                <li className="item-social-item">
                                    <span className="item-social-splitter">/</span>
                                    <a className="item-social-icon i_social_youtube j-to-footer-ga-click lazyload" href="https://www.youtube.com/@mirror2050" target="_blank" rel="nofollow noopener noreferrer" data-type="footerNav-goYoutube" data-gatype="Youtube">youtube</a>
                                </li>
                                <li className="item-social-item">
                                    <span className="item-social-splitter">/</span>
                                    <a href="https://www.instagram.com/mirrortwentyfifty" target="_blank"><img className="instagram-img" src="instagram-logo.png" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer-bottom">
                    <p class="item-copyright">© Mirror 2050</p>
                    {/* <div class="lang-change-wrap">
                        <div class="lang-change-container">
                            <a class="lang-change-txt" href="javascript:;">English</a>
                            <span class="item-nav-item-arrow"></span>
                        </div>
                        <div class="item-dropdown dropdown-lang">
                            <ul class="item-dropdown-list">
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="en">English</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="de">Deutsch</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="es">Español</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="fr">Français</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="it">Italiano</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="pt">Português</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="ja">日本語</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="zh-cn">简体中文</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                                <li class="item-dropdown-item">
                                    <a class="item-choose-lang" href="javascript:;" data-lang="zh-tw">繁體中文</a>
                                </li>
                                <li class="item-dropdown-item item-dropdown-item-division"></li>
                            </ul>
                            <i class="item-dropdown-arrow-down"></i>
                        </div>
                    </div> */}
                </div>
            </div>
            {/* <div className="back-top i-hide" style={{display: "none"}}>
                <i className="img-index i_dyn_top_hover" style={{bottom: "45px"}}></i>
            </div> */}
        </div>
    );
};
export default Footer;