import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer";
import ContactUsBanner from "../components/ContactUsBanner/ContactUsBanner";
import ContactUsForm from "../components/ContactUsForm/ContactUsForm";


const ContactUs = () => {
  return (
    <>
      <Navbar/>
        <div className="public-header-position"></div>
        <div className="solution-wrap">
            
            <ContactUsBanner/>
            <ContactUsForm/>
            <Footer />


        </div> 
    </>
  )
};

export default ContactUs;