import { Outlet, Link } from "react-router-dom";
import Navbar from "../components/Navbar/Navbar"
import Banner from "../components/Banner/Banner"
import IntroPanel from "../components/IntroPanel/IntroPanel";
import OutroPanel from "../components/OutroPanel/OutroPanel";
import PanelList from "../components/PanelList/PanelList";
import Comments from '../components/Comments/Comments'
import Footer from "../components/Footer/Footer";
const Layout = () => {
  return (
    <>
        <Navbar/>
        <div className="public-header-position"></div>
        <div className="solution-wrap">
            
            <Banner/>
            <IntroPanel/>
            <PanelList/>
            <Comments/>
            <OutroPanel/>
            <Footer />

        </div> 
    </>
)
};

export default Layout;