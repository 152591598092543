import Footer from "../components/Footer/Footer"
import Navbar from "../components/Navbar/Navbar"

const TermsAndConditions = () =>{
    return (
       <>
       <Navbar/>
       <br/><br/><br/><br/><br/>
       
<p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <strong>Terms &amp; Conditions</strong></p>
<p><strong>&nbsp;</strong></p>
<p>Mirror2050 Premium Administrations Arrangement</p>
<p>The Mirror2050 Premium Administrations Understanding (thereinafter, the &quot;Arrangement&quot;) is together finished up by You (the client) and MIRROR2050 for Your utilization of the Top notch Administrations (as characterized beneath) given by MIRROR2050. &quot;MIRROR2050&quot; alludes to MIRROR2050. or potentially any related administrator (hereinafter on the whole alluded to as &quot;MIRROR2050&quot;, &quot;Organization&quot;&nbsp;，&nbsp;&quot;We&quot;，&nbsp;&quot;Us&quot; or &quot;Our&quot;) that might exist concerning its connected administrations. Both You and We will be legitimately limited by this Understanding. The terms in this Understanding apply when You decide to move up to an Exceptional Record or utilize our other paid administrations (all in all, &quot;Top notch Administrations&quot;) as an INDIVIDUAL as opposed to big business. You ought to peruse this Arrangement cautiously to figure out Your freedoms as well as limitations before Your membership/utilization of the Top notch Administrations. In the event that You don&apos;t consent to any or every one of the provisions of this Arrangement, if it&apos;s not too much trouble, quit buying into/utilizing the Exceptional Administrations. By utilizing the Superior Administrations, You concur that You have perused and figured out this Understanding, and that You buy into/utilization the Exceptional Administrations as per this Arrangement.</p>
<p>&nbsp;</p>
<p>The conditions of this Understanding that are or might be material to Your privileges and interests have been set apart in strong, and kindly give explicit consideration to them.</p>
<p>&nbsp;</p>
<p>On the off chance that You are an Endeavour Client, representative, specialist, legal administrator of a Venture Client, approved individual to administer or deal with the utilization of Mirror2050 by an Undertaking Client, or other individual to involve Mirror2050 for the interest of a venture, kindly visit the Mirror2050 Instalment Arrangement for Big business Services read and choose whether to settle on the Mirror2050 Instalment Understanding for Big business Administrations.</p>
<p>&nbsp;</p>
<p>Prior to buying into/utilizing Premium Administrations, kindly affirm that You are a characteristic individual (not a venture) with full limit with respect to common behaviors under relevant regulations and can go into an official agreement without help from anyone else, generally kindly read this Arrangement cautiously with Your guardian(s) and work with the express assent of the guardian(s).</p>
<p>&nbsp;</p>
<p>Because of the progressions in our business and regulations and guidelines that concern You and Us, We might refresh the details of this Arrangement whenever without notice. The details of the Arrangement at the time You affirm the exchange will apply to the exchange.</p>
<p>&nbsp;</p>
<p>I. Expenses and Instalment</p>
<p>1. You consent to pay for the Superior Administrations in the cash determined by the Us while buying into/utilizing the Top notch Administrations and to expect all dangers related with changes in the worth of the money contrasted with different monetary standards.</p>
<p>&nbsp;</p>
<p>2. We acknowledge PayPal, some charge and Mastercards, and so forth as instalment strategies for Premium Administrations. At the point when You buy utilizing the previously mentioned instalment strategy, the expenses for Your Top notch Administrations will be charged on the date You buy the Superior Administrations, and the Exceptional Administrations will come full circle following instalment.</p>
<p>&nbsp;</p>
<p>3. The predetermined expenses for Premium Administrations might incorporate specific assessments or comparable charges material to Your acquisition of the Top notch Administrations. If any duties or expenses pertinent to a buy are not charged by us, You recognize that You are exclusively liable for making good on the assessments or different charges.</p>
<p>&nbsp;</p>
<p>4. Our cost for Premium Administrations does exclude any of the outsider expenses You cause regarding utilizing the Superior Administrations, including however not restricted to charges charged by Your Web access supplier, expenses for information administrations charged by any remote transporters and some other expenses charged by outsider applications or administrations which You might utilize while paying for the Exceptional Administrations. You get it and concur that You are exclusively liable for important outsider charges.</p>
<p>&nbsp;</p>
<p>5. We might send You &quot;actuation codes&quot; or &quot;markdown coupons&quot; contingent upon conditions. For the situation that You have an initiation code, when the enactment code is confirmed by Us, You could approach the Superior Administrations for a specific timeframe, toward the finish of which the Exceptional Administrations will end on the off chance that You don&apos;t buy into them before the finish of the previously mentioned timeframe. For the situation that You have a markdown coupon, You can partake in a rebate when You pay for the Superior Administrations utilizing that coupon under the circumstances distributed by us.</p>
<p>&nbsp;</p>
<p>6. We maintain whatever authority is needed to charge various costs because of various zones and districts of the world in which the client is based.</p>
<p>&nbsp;</p>
<p>7. Your Paid Venture Administrations incorporate the inclusion of information utilization and traffic caused by performing distant meetings as well as different highlights inside our administrations. We maintain whatever authority is needed to charge extra expenses assuming the information utilization is reliably more prominent than the ordinary pace of utilization by our clients.</p>
<p>&nbsp;</p>
<p>II. Programmed Restoration</p>
<p>1. On the off chance that You buy into the Exceptional Administrations that restore consequently, You approve Us to pull out from Your instalment account the expense for the following charging cycle prior to the due date of every Superior Administrations charging cycle, and the Exceptional Administrations will continue reestablishing automatically.The Premium Administrations charging cycle could be nevertheless not restricted to a month to month or yearly, and so on charging cycle (contingent upon Your decision). You can browse the charging cycle plans displayed on Mirror2050 when You buy Premium Administrations.</p>
<p>&nbsp;</p>
<p>2. For the situation that You pick the programmed reestablishment, You will tie Your Mirror2050 record to Your approved instalment account and guarantee that We can effectively pull out cash from the previously mentioned account. You will be exclusively answerable for the bombed recharging made by Your disappointment tie Your records or lacking equilibrium in the previously mentioned account.</p>
<p>&nbsp;</p>
<p>3. You get it and concur that assuming the cost for the Mirror2050 Premium Administrations is changed at or before the programmed recharging, the genuine cost after the change will win.</p>
<p>&nbsp;</p>
<p>III. Rules of Purpose for the Exceptional Administrations</p>
<p>1. You can see Your Superior Administrations legitimacy period and other data whenever at Mirror2050. Would it be a good idea for You have any inquiry or complaint, You will promptly reach us. If there is a mistake, We would address it right away.</p>
<p>&nbsp;</p>
<p>2. You are exclusively obligated for any slip-up/misfortunes or obligation emerging during the buy/utilization of the Mirror2050 Premium Administrations because of Your own reasons, for which We will not be at risk. Such conditions incorporate however are not restricted to the accompanying circumstances:</p>
<p>&nbsp;</p>
<p>(1) You don&apos;t work as per the principles of this Understanding or the guidelines on the important pages;</p>
<p>&nbsp;</p>
<p>(2) You buy administrations through channels not acknowledged by Us, or manage exchanges without Our assent, and so forth.;</p>
<p>&nbsp;</p>
<p>(3) You don&apos;t as expected store and deal with Your record or secret key;</p>
<p>&nbsp;</p>
<p>(4) You don&apos;t utilize or use in a mistaken manner the actuation codes or rebate coupons, because of which You can&apos;t enact the Top notch Administrations or get the markdown;</p>
<p>&nbsp;</p>
<p>(5) You sign in to the record of someone else&apos;s;</p>
<p>&nbsp;</p>
<p>(6) Different misfortunes because of Your own plan or gross carelessness.</p>
<p>&nbsp;</p>
<p>Nonetheless, to guarantee Your great experience while utilizing Mirror2050 Administrations, We are free for fundamental help inside the extent of our capacity. You can reach Us promptly in the previously mentioned conditions for fundamental help.</p>
<p>&nbsp;</p>
<p>3. We are not obligated for harms in the accompanying situations when Mirror2050 items and administrations neglect to work appropriately which cripple Your admittance to administrations or cause misfortunes of any virtual property, documents, information, and so on. Such cases incorporate yet are not restricted to:</p>
<p>&nbsp;</p>
<p>(1) Misfortunes because of Your inappropriate activity during the personal time informed by Us for support, overhauls and change of the framework;</p>
<p>&nbsp;</p>
<p>(2) Disappointment of information transmission or typical help conveyance because of breakdown of broadcast communications or hardware;</p>
<p>&nbsp;</p>
<p>(3) Disappointment of working of Mirror2050 items or administrations brought about forcibly Majeure, for example, tropical storms, tremors, waves, floods, power disappointments, wars, fear monger assaults, unofficial law, and so on.;</p>
<p>&nbsp;</p>
<p>(4) Loss of Your record or the information in Your record, interference or postponement of administration brought about by programmer assaults, specialized change or glitch of the broadcast communications division, site redesigns, framework precariousness, Power Majeure (for example server crash) and issues of related outsiders;</p>
<p>&nbsp;</p>
<p>(5) Different circumstances that can&apos;t be ascribed to Us.</p>
<p>&nbsp;</p>
<p>4. Assuming You neglect to make full and ideal instalment as per this Understanding for the Superior Administrations or, in the programmed reestablishment case, the restoration because of reasons including the responsible bank&apos;s refusal to move the sum and different reasons that lead to the disappointment of the instalment (You have the obligation to guarantee that Your charge card data in Your record is legitimate), We might suspend Your entitlement to utilize the Top notch Administration, or We might change the kind of honours You have to a non-premium rendition of the administrations (e.g., from Mirror2050 Premium Administrations to a free variant Mirror2050 Administrations).</p>
<p>&nbsp;</p>
<p>We may likewise decide not to drop Your Superior Administrations right away, in which case You will keep on paying for the charges of the membership/programmed reestablishment of Premium Administrations. We will pull out the sums for the previously mentioned charge when such withdrawal is free with Your instalment account, and don&apos;t bar the chance of guaranteeing the leftover piece of the previously mentioned expense through strategies including assertion, and so on.</p>
<p>&nbsp;</p>
<p>To guarantee Your great experience while utilizing Mirror2050 Administrations, We will attempt to illuminate You immediately assuming You neglect to come up with all required funds and on time, however We are not obliged to do as such.</p>
<p>&nbsp;</p>
<p>IV. IV. Abrogation of Premium Administrations and the Discount</p>
<p>1. For the situation that the Superior Administrations You buy into do exclude the programmed reestablishment, the Top notch Administrations end consequently toward the finish of the Exceptional Administrations charging cycle for which You have paid. You get it and concur that You won&apos;t get any discount or repayment for the Exceptional Administrations assuming You decide to drop the Top notch Administrations before the due date of the charging cycle for which You have paid. The Top notch Administrations You have bought into will in any case end toward the finish of the Superior Administrations charging cycle for which You have paid.</p>
<p>&nbsp;</p>
<p>2. For the situation that the Top notch Administrations You buy into incorporate the programmed restoration, You might decide to drop the programmed reestablishment. To drop the programmed reestablishment, You need to demand a scratch-off before the Superior Administrations charging cycle which You are in and for which You have paid is expected. On the off chance that the scratch-off is effective, Your Exceptional Administrations will end toward the finish of the Superior Administrations charging cycle which You are in and for which You have paid, and won&apos;t restore consequently beginning from the ensuing charging cycle. Assuming the expense for the resulting charging cycle has previously been removed from the instalment account that You have approved when You demand the scratch-off, You can demand a discount for the ensuing charging cycle keeping the guidelines in &quot;(1)&quot;, &quot;(2)&quot; underneath. To drop the Superior Administrations including the programmed recharging, You will demand the crossing out adhering to the guidelines underneath:</p>
<p>&nbsp;</p>
<p>(1) In the event that Your Exceptional Administrations are charged consistently, and You have not utilized any of the Superior Administrations in the span of 7 days from the very outset of another charging cycle, You might demand the abrogation of the Top notch Administrations and the programmed restoration in something like 7 days from the very beginning of such charging cycle. In the event that such undoing is supported by Us, We will discount the charge for such charging cycle. Assuming You neglect to demand the abrogation in no less than 7 days from the outset of such charging cycle, or have proactively utilized the Superior Administrations in no less than 7 days from the very start of such charging cycle, the expense for such charging cycle won&apos;t be discounted. Be that as it may, You can in any case demand the abrogation of the programmed recharging under such situation. In the event that the undoing of the programmed reestablishment is effective, Your Superior Administrations will subsequently quit recharging consequently, and the Exceptional Administrations will end toward the finish of such charging cycle.</p>
<p>&nbsp;</p>
<p>(2) On the off chance that Your Exceptional Administrations are charged on a yearly premise, You can demand the wiping out in the span of 7 days after You have bought the Superior Administrations or in no less than 7 days after the beginning of another charging cycle. Assuming such crossing out is endorsed by Us, We will discount You the charge for such charging cycle. Assuming that You neglect to demand the dropping in somewhere around 7 days after You have bought the Exceptional Administrations or from the outset of such charging cycle, the expense for such charging cycle won&apos;t be discounted. In any case, You can in any case demand the scratch-off of the programmed reestablishment under such situation. On the off chance that the scratch-off of the programmed restoration is fruitful, Your Top notch Administrations and the programmed recharging will stop toward the finish of such charging cycle.</p>
<p>&nbsp;</p>
<p>3. To drop the programmed reestablishment, You might sign in to Your record on the Mirror2050 site, visit Your &quot;Record data&quot; on the &quot;Client Center&quot; site, and snap &quot;Cripple auto-restore&quot;. The more information can be perused on the site article, and We will continue such crossing out as per 2, IV of this Arrangement.</p>
<p>&nbsp;</p>
<p>4. Given that the circumstances expressed in 2, IV of this Understanding are met, the discount will be sent back to Your instalment account inside 45 working days after Your crossing out demand is supported. Kindly ensure that Your instalment account is legitimate for use. In any case, We are not answerable for any disappointment of discount or Your inability to get the discount emerging from the shortcoming of Your instalment account.</p>
<p>&nbsp;</p>
<p>V. Changes in Administrations and the Arrangement</p>
<p>You get it and concur that We might change the cost for the Top notch Administrations, charge new expenses for specific capabilities or change, change, suspend or end parts or all of this Arrangement because of changes in business and regulations and guidelines that concern You and Us. Except if We advise You any other way, these progressions won&apos;t concern You until the termination of Your ongoing powerful Premium Administrations charging cycle and will possibly concern You when the ensuing charging cycle begins. In the event that You don&apos;t consent to the new cost or other relevant charges, or any terms after the changes, You might decide to drop the reestablishment of Premium Administrations before the cost changes apply to You.For data on the most proficient method to drop the programmed recharging of the Top notch Administrations, see &quot;Crossing out of Premium Administrations&quot; above.</p>
<p>&nbsp;</p>
<p>VI. Random</p>
<p>1. At the point when You partake in the Superior Administrations, You will be limited by the Mirror2050 End Client Permit Understanding. This Understanding is strengthening to the Mirror2050 End Client Permit Arrangement and is a basic piece of the Mirror2050 End Client Permit Arrangement. In the event of any contention between the guidelines of this Understanding and the Mirror2050 End Client Permit Agreement, the rules in this Arrangement will win. By utilizing the Top notch Administrations, You consent to the particulars of the Superior Administrations and all open warnings We issue for these administrations.</p>
<p>&nbsp;</p>
<p>2. The end, passage into force, understanding, alteration, supplement, execution, suspension, end and debate settlement of this Arrangement will be represented by and understood as per the laws of the Republic of Singapore; in the event that there are no applicable arrangements in the law, reference will be made to business rehearses or potentially modern practices.</p>
<p>&nbsp;</p>
<p>3. The questions emerging out of or regarding Your utilization of the Mirror2050 Premium Administrations will be settled through well disposed meeting among You and Us.</p>
<p>4. Assuming any arrangement of this Understanding is negated, invalid or unenforceable, such arrangement is distinct, and the legitimacy and enforceability of the excess arrangements of this Understanding will not at all be impacted or impeded accordingly.</p>
<p>&nbsp;</p>
<p>5. MIRROR2050 saves the right of conclusive translation of this Understanding.</p>
<p>&nbsp;</p>
<p>VII. Get in touch with Us</p>
<p>Would it be advisable for You have any inquiries, grievance or reimbursement guarantee, kindly get in touch with Us through: <a href="mailto:info@mirror2050.com">info@mirror2050.com</a>.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
       <Footer/>
       </>   
    )
}

export default TermsAndConditions